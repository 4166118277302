import React from 'react';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import RouteApp from './routes/RouteApp';
import RouteLP, {loader as lpLoader } from './routes/RouteLP';
import RouteError from './routes/RouteError';

const router = createBrowserRouter([
    {
        path: "/",
        element: (
            <RouteApp />
        ),
    },
    {
        path: "/:lpId", 
        loader: lpLoader,
        element: (
            <RouteLP />
        ),
        children: [
            {
                path: "explore",
                async lazy() {
                    let { default: RouteExplore } = await import(
                    "./routes/RouteExplore"
                    );
                    return {
                        loader: lpLoader,
                        Component: RouteExplore,
                    };
                },
                children: [
                    {
                        path: "3-circles",
                        async lazy() {
                            let { default: RouteExploreThreeCircles } = await import(
                            "./routes/RouteExploreThreeCircles"
                            );
                            return {
                                loader: lpLoader,
                                Component: RouteExploreThreeCircles,
                            };
                        },
                    },
                    {
                        path: "testimony",
                        async lazy() {
                            let { default: RouteExploreTestimony } = await import(
                            "./routes/RouteExploreTestimony"
                            );
                            return {
                                loader: lpLoader,
                                Component: RouteExploreTestimony,
                            };
                        },
                    },
                    {
                        path: "stories-of-hope",
                        async lazy() {
                            let { default: RouteExploreSOH } = await import(
                            "./routes/RouteExploreSOH"
                            );
                            return {
                                loader: lpLoader,
                                Component: RouteExploreSOH,
                            };
                        },
                        children: [
                            {
                                path: "review",
                                async lazy() {
                                    let { default: RouteExploreSOHReview } = await import(
                                    "./routes/RouteExploreSOHReview"
                                    );
                                    return {
                                        loader: lpLoader,
                                        Component: RouteExploreSOHReview,
                                    };
                                },
                            },
                            {
                                path: ":sohId",
                                async lazy() {
                                    let { default: RouteExploreSOHLesson, loader: SOHLessonLoader } = await import(
                                    "./routes/RouteExploreSOHLesson"
                                    );
                                    return {
                                        loader: SOHLessonLoader,
                                        Component: RouteExploreSOHLesson,
                                    };
                                },
                                children: [
                                    {
                                        path: 'reflection/:sohReflectionId',
                                        async lazy() {
                                            let { default: RouteExploreSOHLessonReflection, loader: SOHLessonReflectionLoader } = await import(
                                            "./routes/RouteExploreSOHLessonReflection"
                                            );
                                            return {
                                                loader: SOHLessonReflectionLoader,
                                                Component: RouteExploreSOHLessonReflection,
                                            };
                                        },
                                    },
                                    {
                                        path: '*',
                                        element: ( <>Not found</> )
                                    },
                                ]
                            },
                        ]
                    },
                    {
                        path: "next-steps",
                        async lazy() {
                            let { default: RouteExploreNextSteps } = await import(
                            "./routes/RouteExploreNextSteps"
                            );
                            return {
                                loader: lpLoader,
                                Component: RouteExploreNextSteps,
                            };
                        },
                    },
                ]
            },
            {
                path: "respond",
                async lazy() {
                    let { default: RouteRespond } = await import(
                    "./routes/RouteRespond"
                    );
                    return {
                        loader: lpLoader,
                        Component: RouteRespond,
                    };
                },
                children: [
                    {
                        path: "repentance",
                        async lazy() {
                            let { default: RouteRespondRepentance } = await import(
                            "./routes/RouteRespondRepentance"
                            );
                            return {
                                loader: lpLoader,
                                Component: RouteRespondRepentance,
                            };
                        },
                    },
                    {
                        path: "baptism",
                        async lazy() {
                            let { default: RouteRespondBaptism } = await import(
                            "./routes/RouteRespondBaptism"
                            );
                            return {
                                loader: lpLoader,
                                Component: RouteRespondBaptism,
                            };
                        },
                    },
                    {
                        path: "forgiveness",
                        async lazy() {
                            let { default: RouteRespondForgiveness } = await import(
                            "./routes/RouteRespondForgiveness"
                            );
                            return {
                                loader: lpLoader,
                                Component: RouteRespondForgiveness,
                            };
                        },
                    },
                    {
                        path: "holy-spirit",
                        async lazy() {
                            let { default: RouteRespondHolySpirit } = await import(
                            "./routes/RouteRespondHolySpirit"
                            );
                            return {
                                loader: lpLoader,
                                Component: RouteRespondHolySpirit,
                            };
                        },
                    },
                    {
                        path: "multiplication",
                        async lazy() {
                            let { default: RouteRespondMultiplication } = await import(
                            "./routes/RouteRespondMultiplication"
                            );
                            return {
                                loader: lpLoader,
                                Component: RouteRespondMultiplication,
                            };
                        },
                    },
                    {
                        path: "belonging",
                        async lazy() {
                            let { default: RouteRespondBelonging } = await import(
                            "./routes/RouteRespondBelonging"
                            );
                            return {
                                loader: lpLoader,
                                Component: RouteRespondBelonging,
                            };
                        },
                    },
                    {
                        path: "doing",
                        async lazy() {
                            let { default: RouteRespondDoing } = await import(
                            "./routes/RouteRespondDoing"
                            );
                            return {
                                loader: lpLoader,
                                Component: RouteRespondDoing,
                            };
                        },
                    },
                    {
                        path: "next-steps",
                        async lazy() {
                            let { default: RouteRespondNextSteps } = await import(
                            "./routes/RouteRespondNextSteps"
                            );
                            return {
                                loader: lpLoader,
                                Component: RouteRespondNextSteps,
                            };
                        },
                    },
                ]
            },
            {
                path: "grow",
                async lazy() {
                    let { default: RouteGrow } = await import(
                    "./routes/RouteGrow"
                    );
                    return {
                        loader: lpLoader,
                        Component: RouteGrow,
                    };
                },
                children: [
                    {
                        path: "biblical-teaching",
                        async lazy() {
                            let { default: RouteGrowBiblicalTeaching } = await import(
                            "./routes/RouteGrowBiblicalTeaching"
                            );
                            return {
                                loader: lpLoader,
                                Component: RouteGrowBiblicalTeaching,
                            };
                        },
                    },
                    {
                        path: "understanding-baptism",
                        async lazy() {
                            let { default: RouteGrowUnderstandingBaptism } = await import(
                            "./routes/RouteGrowUnderstandingBaptism"
                            );
                            return {
                                loader: lpLoader,
                                Component: RouteGrowUnderstandingBaptism,
                            };
                        },
                    },
                    {
                        path: "prayer",
                        async lazy() {
                            let { default: RouteGrowPrayer } = await import(
                            "./routes/RouteGrowPrayer"
                            );
                            return {
                                loader: lpLoader,
                                Component: RouteGrowPrayer,
                            };
                        },
                    },
                    {
                        path: "lords-supper",
                        async lazy() {
                            let { default: RouteGrowLordsSupper } = await import(
                            "./routes/RouteGrowLordsSupper"
                            );
                            return {
                                loader: lpLoader,
                                Component: RouteGrowLordsSupper,
                            };
                        },
                    },
                    {
                        path: "loving-serving",
                        async lazy() {
                            let { default: RouteGrowLovingServing } = await import(
                            "./routes/RouteGrowLovingServing"
                            );
                            return {
                                loader: lpLoader,
                                Component: RouteGrowLovingServing,
                            };
                        },
                    },
                    {
                        path: "appointed-leadership",
                        async lazy() {
                            let { default: RouteGrowAppointedLeadership } = await import(
                            "./routes/RouteGrowAppointedLeadership"
                            );
                            return {
                                loader: lpLoader,
                                Component: RouteGrowAppointedLeadership,
                            };
                        },
                    },
                    {
                        path: "giving",
                        async lazy() {
                            let { default: RouteGrowGiving } = await import(
                            "./routes/RouteGrowGiving"
                            );
                            return {
                                loader: lpLoader,
                                Component: RouteGrowGiving,
                            };
                        },
                    },
                    {
                        path: "accountability",
                        async lazy() {
                            let { default: RouteGrowAccountability } = await import(
                            "./routes/RouteGrowAccountability"
                            );
                            return {
                                loader: lpLoader,
                                Component: RouteGrowAccountability,
                            };
                        },
                    },
                    {
                        path: "singing-praising",
                        async lazy() {
                            let { default: RouteGrowSingingPraising } = await import(
                            "./routes/RouteGrowSingingPraising"
                            );
                            return {
                                loader: lpLoader,
                                Component: RouteGrowSingingPraising,
                            };
                        },
                    },
                    {
                        path: "making-disciples",
                        async lazy() {
                            let { default: RouteGrowMakingDisciples } = await import(
                            "./routes/RouteGrowMakingDisciples"
                            );
                            return {
                                loader: lpLoader,
                                Component: RouteGrowMakingDisciples,
                            };
                        },
                    },
                    {
                        path: "next-steps",
                        async lazy() {
                            let { default: RouteGrowNextSteps } = await import(
                            "./routes/RouteGrowNextSteps"
                            );
                            return {
                                loader: lpLoader,
                                Component: RouteGrowNextSteps,
                            };
                        },
                    },
                ]
            },
            {
                path: "share",
                async lazy() {
                    let { default: RouteShare } = await import(
                    "./routes/RouteShare"
                    );
                    return {
                        loader: lpLoader,
                        Component: RouteShare,
                    };
                },
                children: [
                    {
                        path: "how",
                        async lazy() {
                            let { default: RouteShareHow } = await import(
                            "./routes/RouteShareHow"
                            );
                            return {
                                loader: lpLoader,
                                Component: RouteShareHow,
                            };
                        },
                        children: [
                            {
                                path: "disciple",
                                async lazy() {
                                    let { default: RouteShareHowDisciple } = await import(
                                    "./routes/RouteShareHowDisciple"
                                    );
                                    return {
                                        loader: lpLoader,
                                        Component: RouteShareHowDisciple,
                                    };
                                }
                            },
                            {
                                path: "audience",
                                async lazy() {
                                    let { default: RouteShareHowAudience } = await import(
                                    "./routes/RouteShareHowAudience"
                                    );
                                    return {
                                        loader: lpLoader,
                                        Component: RouteShareHowAudience,
                                    };
                                }
                            },
                            {
                                path: "content",
                                async lazy() {
                                    let { default: RouteShareHowContent } = await import(
                                    "./routes/RouteShareHowContent"
                                    );
                                    return {
                                        loader: lpLoader,
                                        Component: RouteShareHowContent,
                                    };
                                }
                            },
                            {
                                path: "respond",
                                async lazy() {
                                    let { default: RouteShareHowRespond } = await import(
                                    "./routes/RouteShareHowRespond"
                                    );
                                    return {
                                        loader: lpLoader,
                                        Component: RouteShareHowRespond,
                                    };
                                }
                            },
                        ]
                    },
                    {
                        path: "strategically",
                        async lazy() {
                            let { default: RouteShareStrategically } = await import(
                            "./routes/RouteShareStrategically"
                            );
                            return {
                                loader: lpLoader,
                                Component: RouteShareStrategically,
                            };
                        },
                        children: [
                            {
                                path: "course",
                                async lazy() {
                                    let { default: RouteShareStrategicallyCourse } = await import(
                                    "./routes/RouteShareStrategicallyCourse"
                                    );
                                    return {
                                        loader: lpLoader,
                                        Component: RouteShareStrategicallyCourse,
                                    };
                                }
                            },
                            {
                                path: "cohort",
                                async lazy() {
                                    let { default: RouteShareStrategicallyCohort } = await import(
                                    "./routes/RouteShareStrategicallyCohort"
                                    );
                                    return {
                                        loader: lpLoader,
                                        Component: RouteShareStrategicallyCohort,
                                    };
                                },
                            },
                            {
                                path: "partnership",
                                async lazy() {
                                    let { default: RouteShareStrategicallyPartnership } = await import(
                                    "./routes/RouteShareStrategicallyPartnership"
                                    );
                                    return {
                                        loader: lpLoader,
                                        Component: RouteShareStrategicallyPartnership,
                                    };
                                },
                            }
                        ]
                    },
                    {
                        path: "next-steps",
                        async lazy() {
                            let { default: RouteShareNextSteps } = await import(
                            "./routes/RouteShareNextSteps"
                            );
                            return {
                                loader: lpLoader,
                                Component: RouteShareNextSteps,
                            };
                        },
                    },
                ]
            },
        ],
        errorElement: <RouteError />,
    },
]);

function App(){
  return <RouterProvider router={router} />
}

export default App; 
