import React from 'react';

function RouteApp(){
    return (
        <>
        Route App
        </>
    );
}

export default RouteApp;
