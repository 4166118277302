import React from 'react';
import { Outlet, Link } from "react-router-dom";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';

export async function loader({ params }) {
    return params.lpId;
}

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

function RouteLP(){

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid xs={2}>
                    <img width="200px" src="/asklogo.png" />
                </Grid>
                <Grid xs={10}>
                    <Item><h1>Top Bar</h1></Item>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid xs={12}>
                    <Item><h2>Which statement best describes you?</h2></Item>
                </Grid>
                <Grid xs={3}>
                    <Item><p><Link to="explore">Explore</Link></p></Item>
                </Grid>
                <Grid xs={3}>
                    <Item><p><Link to="respond">Respond</Link></p></Item>
                </Grid>
                <Grid xs={3}>
                    <Item><p><Link to="grow">Grow</Link></p></Item>
                </Grid>
                <Grid xs={3}>
                    <Item><p><Link to="share">Share</Link></p></Item>
                </Grid>
            </Grid>
            <Outlet />
        </Box>
    );
}

export default RouteLP;
