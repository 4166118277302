import React from 'react';

function RouteError(){
    return (
        <>
        Route Error
        </>
    );
}

export default RouteError;
